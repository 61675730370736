import React, { useState } from "react";
import "../App.css";

const MyButton = (props) => {
  return (
    <button
      data-testid={props.testId}
      onClick={props?.action}
      className={"CTA"}
      style={
        props?.isPrimary && {
          background: "linear-gradient(90deg, #540199 0%, #1248ed 100%)",
          color: "#fff",
          fontWeight: 700,
        }
      }
    >
      {props?.text}
    </button>
  );
};

export default MyButton;
