import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QrReader from "modern-react-qr-reader";
//custom imports:
import { ReactComponent as Logo } from "../logo_scanner.svg";
import { ReactComponent as Toggle } from "../assets/camera_toggle.svg";
import { useScannerContext } from "../hooks/scanner";
import { getScanner } from "../actions";
import { formatDate } from "../utils/datetimeFormat";
import "../App.css";

const Scanner = (props) => {
  const [cameraMode, setCameraMode] = useState("environment");
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  const { scanner, dispatch } = useScannerContext();
  const navigate = useNavigate();

  const fetchScanner = async () => {
    if (inProgress) return;
    if (error) setError("");
    setInProgress(true);
    let path = window.location.pathname;
    try {
      const res = await getScanner(path);
      if (res?.scanner)
        dispatch({ type: "SET_SCANNER", payload: res.scanner });
    } catch (error) {
      console.log(error.message);
    }
    setInProgress(false);
  };

  useEffect(() => {
    if (!scanner) {
      fetchScanner().then();
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        dispatch({ type: "UNSET_SCANNER" });
      } else {
        fetchScanner().then();
      }
    };

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );

    return () => {
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
    };
  }, []);

  const handleError = (error) => {
    if (error) setError(error);
  };

  const handleScan = (data) => {
    if (error) setError("");
    if (data) navigate("/scanners/" + scanner?.id + "/items/" + data);
  };

  {/* user or environment */}
  // const handleToggleCamera = () => {
  //   if (error) setError("");
  //   setCameraMode(cameraMode === "environment" ? "user" : "environment");
  // };

  return (
    <div className="container">
      <div className="scanner_header">
        <div className="scanner_header_cell">
          <Logo />
        </div>
        <div style={{marginLeft: "-40px"}}
          className="scanner_header_cell"
          data-testid="scanner_header_name"
        >
          {scanner?.name}
        </div>
        <div className="scanner_header_cell pressable">
        {/*  <Toggle onClick={handleToggleCamera} />*/}
        </div>
      </div>
      <div className="center" data-testid="qr_reader">
        {scanner && (
          <QrReader
            delay={500}
            facingMode={"environment"}
            onError={handleError}
            onScan={handleScan}
            showViewFinder={false}
            style={{ width: "100%" , minWidth: "300px"}}
            constraints={{facingMode: "environment"}}
          />
        )}
        <p>{error?.message}</p>
      </div>
      <div className="event_details" data-testid="event_details">
        <div className="event_name" data-testid="event_name">
          {scanner?.event?.name}
        </div>
        <div data-testid="start_date">
          Start: {formatDate(scanner?.event?.start_time)}
        </div>
        <div data-testid="end_date">
          End: {formatDate(scanner?.event?.end_time)}
        </div>
      </div>
      <div className="project_details">
        <img
          data-testid="project_logo"
          className="project_logo"
          src={scanner?.event?.project?.logo}
        />
        <div className="project_name" data-testid="project_name">
          {scanner?.event?.project?.name}
        </div>
      </div>
    </div>
  );
};

export default Scanner;
