import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import NoPage from "./views/noPage";
import Scanner from "./views/scanner";
import Item from "./views/item";
import { ScannerContextProvider } from "./contexts/scanner";

Sentry.init({
    dsn: "https://e6b05448bea4594afee6c8c7be2af9da@o1289712.ingest.us.sentry.io/4506887280328704",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    <ScannerContextProvider>
      <Router>
        <Routes>
          <Route path="scanners/:id/items/:id" element={<Item />} />
          <Route path="scanners/:id" element={<Scanner />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Router>
    </ScannerContextProvider>
  );
}

export default App;
