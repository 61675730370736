import moment from "moment-timezone";

const TIME_ZONE = "Asia/Jerusalem";

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

const formatDate = (date) => {
  if (!date) return;
  return new moment(date).tz(TIME_ZONE).format("ddd, D MMM yyyy - HH:mm");
};

const calculateAge = (date) => {
  if (!date) return "";
  let birthday = new Date(date);
  let ageDifMs = Date.now() - birthday.getTime();
  let ageDate = new Date(ageDifMs); // milliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export { wait, formatDate, calculateAge };
