import { ScannerContext } from "../contexts/scanner";
import { useContext } from "react";

export const useScannerContext = () => {
  const context = useContext(ScannerContext);

  if (!context) {
    throw Error("useScannerContext must be used inside ScannerContextProvider");
  }

  return context;
};
