import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//custom imports:
import "../App.css";
import Card from "../ui/card";
import ScanStatus from "../ui/scanStatus";
import ScanActions from "../ui/scanActions";
import { getItem, createScan } from "../actions";
import { useScannerContext } from "../hooks/scanner";
import { formatDate, wait } from "../utils/datetimeFormat";

const Item = () => {
  const [variant, setVariant] = useState("");
  const [item, setItem] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const { scanner } = useScannerContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleNavigate = () => {
    const [space, scannersPart, scannerId, ...rest] =
      pathname?.split("/");
    const scannerRoute = [space, scannersPart, scannerId].join("/");
    return navigate(scannerRoute);
  };

  const checkIfScanned = (item) => {
    if (item.used || item.scanned_at) {
      setVariant("invalid");
      setErrorMsg(
        "This ticket was already used at " +
          formatDate(item.scanned_at)
      );
    } else setVariant("valid");
  };

  const fetchItem = async () => {
    if (inProgress) return;
    setInProgress(true);
    setItem(null);
    setVariant("");
    setErrorMsg("");
    let path = window.location.pathname;
    const res = await getItem(path);
    if (res?.item) {
      setItem(res.item);
      checkIfScanned(res.item);
      setInProgress(false);
      return;
    }
    setErrorMsg(res.error.msg);
    setVariant("invalid");
    setInProgress(false);
  };

  const useItem = async () => {
    if (inProgress) return;
    setInProgress(true);
    setErrorMsg("");
    const res = await createScan(scanner.id, item.id);
    if (res?.scan) {
      setVariant("used");
      setInProgress(false);
      wait(3000).then(() => handleNavigate());
      return;
    }
    setErrorMsg(res.error.msg);
    setVariant("invalid");
    setInProgress(false);
  };

  useEffect(() => {
    fetchItem();
  }, []);

  return (
    <div className="container">
      <Card variant={variant} person={item?.person} />
      <ScanStatus
        variant={variant}
        product={item?.data?.product}
        error={errorMsg}
      />
      <ScanActions variant={variant} use={useItem} />
    </div>
  );
};

export default Item;
