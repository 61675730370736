import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../App.css";
import MyButton from "./button";

const ScanActions = ({ variant, use }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const handleBack = () => {
    const [space, scannersPart, scannerId, ...rest] = pathname?.split("/");
    const scannerRoute  = [space, scannersPart, scannerId].join("/")
    return navigate(scannerRoute)
  };

  const goBackButton = (text) => (
    <MyButton
      text={text ? text : "Scan next"}
      action={handleBack}
      testId={"button_back"}
    />
  );

  const showActions = () => {
    switch (variant) {
      case "valid":
        return (
          <div>
            <MyButton
              text={"Use ticket"}
              action={use}
              isPrimary={true}
              testId={"button_use"}
            />
            {goBackButton("Cancel")}
          </div>
        );
      case "used":
        return goBackButton();
      case "invalid":
        return goBackButton();
      default:
        return null;
    }
  };
  return <div className="center">{showActions()}</div>;
};

export default ScanActions;
