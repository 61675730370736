import { createContext, useReducer, useEffect } from "react";

export const ScannerContext = createContext();

export const scannerReducer = (state, action) => {
  switch (action.type) {
    case "SET_SCANNER":
      localStorage.setItem("scanner", JSON.stringify(action.payload));
      return { scanner: action.payload };
    case "UNSET_SCANNER":
      localStorage.removeItem("scanner");
      return { scanner: null };
    default:
      return state;
  }
};

export const ScannerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(scannerReducer, {
    event: null,
  });

  useEffect(() => {
    const scanner = JSON.parse(localStorage.getItem("scanner"));

    if (scanner) dispatch({ type: "SET_SCANNER", payload: scanner });
  }, []);

  return (
    <ScannerContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ScannerContext.Provider>
  );
};
