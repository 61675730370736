import React, { useState } from "react";
import { ReactComponent as Error } from "../assets/error.svg";
import { ReactComponent as Avatar } from "../assets/default_avatar.svg";
import "../App.css";
import {calculateAge} from "../utils/datetimeFormat";

const Card = ({ variant, person }) => {
  const userCard = (
    <div className="card_valid center" data-testid="card_valid">
      {person?.avatar ? (
        <img className="card_avatar" src={person?.avatar} alt="avatar" data-testid="person_avatar"/>
      ) : (
        <Avatar className="card_avatar" data-testid="empty_avatar"/>
      )}
      <div className="center card_text">
        <div className="card_name" data-testid="card_name">{person?.name}</div>
        {
            person?.dob && <div className="card_dob" data-testid="card_dob">{calculateAge(person?.dob)}</div>
        }
        {
            person?.gender && <div className="card_gender" data-testid="card_gender">{person?.gender}</div>
        }
        {
            person?.id_number && <div className="card_id_number" data-testid="card_id_number">ID/Passport number: {person?.id_number}</div>
        }
        {/* <div>membership.name</div> */}
      </div>
    </div>
  );
  const showUser = () => {
    switch (variant) {
      case "valid":
        return userCard;
      case "used":
        return userCard;
      case "invalid":
        return <Error data-testid="error"/>;
      default:
        return null;
    }
  };
  return <div className="card center">{showUser()}</div>;
};

export default Card;
