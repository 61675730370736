const getScanner = async (path) => {
  let res = await SendRequest("GET", path);
  return res;
};

const getItem = async (path) => {
  let res = await SendRequest("GET", path);
  return res;
};

const createScan = async (scanner_id, item_id) => {
  let res = await SendRequest(
    "Post",
    "/scanners/" + scanner_id + "/scans",
    JSON.stringify({ scan: { item: { id: item_id } } })
  );
  return res;
};

const SendRequest = async (method, end_point, body) => {
  let path = process.env.REACT_APP_API_URL + end_point;

  try {
    const response = await fetch(path, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: body,
    });
    const res = await response.json();
    return res;
  } catch (e) {
    throw new Error(e);
  }
};

export { getScanner, getItem, createScan };
