import React from "react";
import { ReactComponent as Done } from "../assets/ok.svg";
import "../App.css";

const ScanStatus = ({ variant, product, error }) => {
  const showStatus = () => {
    switch (variant) {
      case "valid":
        return (
          <div>
            <div className="status_text" data-testid="status_text">Valid Ticket</div>
            <div className="status_subtext" data-testid="status_subtext">{product?.name}</div>
          </div>
        );
      case "used":
        return (
          <div>
            <div className="status_img center" data-testid="status_img">
              <Done />
            </div>

            <div className="status_text" data-testid="status_text">Ticket used</div>
          </div>
        );
      case "invalid":
        return (
          <div>
            <div className="status_text" data-testid="status_text">Not Valid</div>
            <div className="status_subtext" data-testid="status_subtext">{error}</div>
          </div>
        );
      default:
        return null;
    }
  };
  return <div className="scanStatusContainer center">{showStatus()}</div>;
};

export default ScanStatus;
